import { Supplier } from '@supy/common';
import { CreateRetailerUserRequest, UpdateRetailerUserRequest } from '@supy/users';

import { GetAllowedEventDateLimitsRequest } from '../../core';

export class CurrentRetailerSet {
  static type = '[CurrentRetailer] Set';

  constructor(readonly payload: { id: string | null; stopRedirect?: boolean }) {}
}

export class CurrentRetailerSuppliersGet {
  static type = '[CurrentRetailer] Suppliers Get';

  constructor(readonly forceFetch?: boolean) {}
}

export class CurrentRetailerSuppliersSet {
  static type = '[CurrentRetailer] Suppliers Set';

  constructor(readonly payload: { suppliers: Supplier[] }) {}
}

export class CurrentRetailerCreateUser {
  static type = '[CurrentRetailer] CreateUser';

  constructor(readonly body: CreateRetailerUserRequest) {}
}

export class CurrentRetailerUpdateUser {
  static type = '[CurrentRetailer] UpdateUser';

  constructor(
    readonly id: string,
    readonly body: UpdateRetailerUserRequest,
  ) {}
}

export class CurrentRetailerGetAllowedEventDateLimits {
  static type = '[CurrentRetailer] GetAllowedEventDateLimits';

  constructor(readonly payload: GetAllowedEventDateLimitsRequest) {}
}
