import { filter, first, map, Observable, switchMap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, inject, Injectable } from '@angular/core';

import { AuthService } from '@supy/authentication';
import { BaseHttpService, IQueryResponse, Query, UserId, UserWithBranches } from '@supy/common';
import { APP_CONFIG } from '@supy/core';

import { RetailersConfig } from '../../config';

@Injectable({
  providedIn: 'root',
})
export class UsersWithBranchesService extends BaseHttpService {
  private readonly authService = inject(AuthService);
  protected readonly httpClient = inject(HttpClient);

  constructor(@Inject(APP_CONFIG) protected readonly config: RetailersConfig) {
    super(`${config.apiUrl}${config.apiUrlPrefix}/users`);
  }

  syncUser(): Observable<UserWithBranches> {
    return this.authService.user$.pipe(
      filter(Boolean),
      first(),
      switchMap(authUser => this.getUserWithBranches(authUser.id)),
    );
  }

  getUserWithBranches(id: UserId): Observable<UserWithBranches> {
    return this.get<UserWithBranches>(`${id}/branches`).pipe(
      map(userWithBranches => ({
        ...userWithBranches,
      })),
    );
  }

  getUsersWithBranches(query: Query<UserWithBranches>): Observable<IQueryResponse<UserWithBranches>> {
    return this.get('branches', query.toQueryParams());
  }
}
