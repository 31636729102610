import produce from 'immer';
import { tap } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';

import { EntityListState, EntityListStateModel, QueryBuilder, QueryPaging } from '@supy/common';

import { RetailerSupplierItem, RetailerSupplierItemsRequestProps, UpdateRetailerSupplierItem } from '../../core';
import { RetailersService } from '../../services';
import {
  RetailerSupplierItemsGetAllData,
  RetailerSupplierItemsGetMany,
  RetailerSupplierItemsImport,
  RetailerSupplierItemsInitFilters,
  RetailerSupplierItemsPatchFilters,
  RetailerSupplierItemsPatchRequestMetadata,
  RetailerSupplierItemsResetFilters,
  RetailerSupplierItemsResetSelectedLocationId,
  RetailerSupplierItemsSetFilters,
  RetailerSupplierItemsSetSelectedLocationId,
  RetailerSupplierItemsUpdate,
} from '../actions';

export interface RetailerSupplierItemsStateModel extends EntityListStateModel<RetailerSupplierItem> {
  filters: RetailerSupplierItemsFilters;
  requestMetadata: RetailerSupplierItemsRequestMetadata;
  responseMetadata: RetailerSupplierItemsResponseMetadata;
  allData?: RetailerSupplierItem[];
  selectedLocationId: string | null;
}

export interface RetailerSupplierItemsRequestMetadata {
  readonly retailerId: string | null;
  readonly supplierId: string | null;
  readonly page: number;
  readonly limit: number;
}

export interface RetailerSupplierItemsResponseMetadata {
  readonly count: number;
  readonly total: number;
}

export interface RetailerSupplierItemsFilters {
  readonly codeName: string | null;
  readonly categories: string[];
}

export class UpdateRetailerSupplierItemsModel {
  readonly items: UpdateRetailerSupplierItem[];
}

const RETAILER_SUPPLIER_ITEMS_STATE_TOKEN = new StateToken<RetailerSupplierItemsStateModel>('retailerSupplierItems');

const FILTERS_DEFAULT = {
  codeName: null,
  categories: [],
};

const REQUEST_METADATA_DEFAULT = {
  page: 0,
  limit: 50,
  retailerId: null,
  supplierId: null,
};

const RESPONSE_META_DEFAULT = { count: 0, total: 0 };

export const ALL_LOCATIONS_OPTION_ID = crypto.randomUUID();

@State<RetailerSupplierItemsStateModel>({
  name: RETAILER_SUPPLIER_ITEMS_STATE_TOKEN,
  defaults: {
    ...EntityListState.default(),
    filters: FILTERS_DEFAULT,
    requestMetadata: REQUEST_METADATA_DEFAULT,
    responseMetadata: RESPONSE_META_DEFAULT,
    selectedLocationId: ALL_LOCATIONS_OPTION_ID,
  },
})
@Injectable()
export class RetailerSupplierItemsState extends EntityListState {
  private readonly retailersService = inject(RetailersService);
  protected readonly router = inject(Router);

  @Selector()
  static filters(state: RetailerSupplierItemsStateModel) {
    return EntityListState.currentFilters<RetailerSupplierItemsFilters>(state);
  }

  @Selector()
  static requestMetadata(state: RetailerSupplierItemsStateModel) {
    return state.requestMetadata;
  }

  @Selector()
  static responseMetadata(state: RetailerSupplierItemsStateModel) {
    return state.responseMetadata;
  }

  @Selector()
  static isFirst(state: RetailerSupplierItemsStateModel) {
    return EntityListState.isFirst(state);
  }

  @Selector()
  static isLast(state: RetailerSupplierItemsStateModel) {
    return EntityListState.isLast(state);
  }

  @Selector()
  static supplierItems(ctx: RetailerSupplierItemsStateModel) {
    return EntityListState.all(ctx);
  }

  @Selector()
  static allData(state: RetailerSupplierItemsStateModel) {
    return state.allData;
  }

  @Selector()
  static selectedLocationId(state: RetailerSupplierItemsStateModel) {
    return state.selectedLocationId;
  }

  @Action(RetailerSupplierItemsInitFilters)
  itemsInitFilter(ctx: StateContext<RetailerSupplierItemsStateModel>) {
    super.initFilter(ctx, FILTERS_DEFAULT);
  }

  @Action(RetailerSupplierItemsSetFilters)
  setItemsFilter(ctx: StateContext<RetailerSupplierItemsStateModel>, action: RetailerSupplierItemsSetFilters) {
    super.setFilter(ctx, action.payload, FILTERS_DEFAULT);
    ctx.dispatch(new RetailerSupplierItemsGetMany());
  }

  @Action(RetailerSupplierItemsPatchFilters)
  patchItemsFilter(ctx: StateContext<RetailerSupplierItemsStateModel>, action: RetailerSupplierItemsPatchFilters) {
    super.patchFilter(ctx, action.payload, FILTERS_DEFAULT);
    ctx.dispatch(new RetailerSupplierItemsGetMany());
  }

  @Action(RetailerSupplierItemsResetFilters)
  resetItemsFilter(ctx: StateContext<RetailerSupplierItemsStateModel>) {
    super.resetFilter(ctx, FILTERS_DEFAULT);
    ctx.dispatch(new RetailerSupplierItemsGetMany());
  }

  @Action(RetailerSupplierItemsPatchRequestMetadata)
  patchRequestMetadata(
    ctx: StateContext<RetailerSupplierItemsStateModel>,
    { payload }: RetailerSupplierItemsPatchRequestMetadata,
  ) {
    ctx.setState(
      produce(draft => {
        draft.requestMetadata = {
          ...ctx.getState().requestMetadata,
          ...payload,
        };
      }),
    );
  }

  @Action(RetailerSupplierItemsGetMany)
  getMany(ctx: StateContext<RetailerSupplierItemsStateModel>) {
    const { requestMetadata } = ctx.getState();
    const { retailerId, supplierId } = requestMetadata;

    if (!retailerId || !supplierId) {
      return;
    }

    return this.retailersService.getSupplierItems(retailerId, supplierId, this.getQuery(ctx.getState())).pipe(
      tap(({ data, metadata }) => {
        ctx.patchState({ responseMetadata: metadata });
        super.setMany(ctx, data);
      }),
    );
  }

  @Action(RetailerSupplierItemsGetAllData)
  getAllData(ctx: StateContext<RetailerSupplierItemsStateModel>) {
    const { requestMetadata, selectedLocationId } = ctx.getState();
    const { retailerId, supplierId } = requestMetadata;

    if (!retailerId || !supplierId) {
      return;
    }

    const qb = new QueryBuilder<RetailerSupplierItem & RetailerSupplierItemsRequestProps>({
      paging: QueryPaging.NoLimit,
      ordering: [
        {
          by: 'name.en',
          dir: 'asc',
        },
        { by: 'id', dir: 'desc' },
      ],
      filtering: [],
    });

    if (selectedLocationId !== ALL_LOCATIONS_OPTION_ID) {
      qb.filtering.setFilter({
        by: 'branch.id',
        match: selectedLocationId as string,
        op: 'eq',
      });
    }

    return this.retailersService.getSupplierItems(retailerId, supplierId, qb.build()).pipe(
      tap(({ data }) => {
        ctx.patchState({ allData: data });
      }),
    );
  }

  @Action(RetailerSupplierItemsImport)
  importSupplierItems(ctx: StateContext<RetailerSupplierItemsStateModel>, { payload }: RetailerSupplierItemsImport) {
    const { requestMetadata, selectedLocationId } = ctx.getState();
    const { retailerId, supplierId } = requestMetadata;

    if (!retailerId || !supplierId) {
      return;
    }

    if (selectedLocationId !== ALL_LOCATIONS_OPTION_ID) {
      payload = {
        ...payload,
        location: { id: selectedLocationId as string },
      };
    }

    return this.retailersService
      .importSupplierItems(retailerId, supplierId, payload)
      .pipe(tap(() => ctx.dispatch(new RetailerSupplierItemsGetMany())));
  }

  @Action(RetailerSupplierItemsUpdate)
  update(ctx: StateContext<RetailerSupplierItemsStateModel>, { payload }: RetailerSupplierItemsUpdate) {
    const { requestMetadata } = ctx.getState();
    const { retailerId, supplierId } = requestMetadata;

    if (!retailerId || !supplierId) {
      return;
    }

    return this.retailersService
      .updateSupplierItems(retailerId, supplierId, payload)
      .pipe(tap(() => ctx.dispatch(new RetailerSupplierItemsGetMany())));
  }

  @Action(RetailerSupplierItemsSetSelectedLocationId)
  setSelectedLocationId(
    ctx: StateContext<RetailerSupplierItemsStateModel>,
    { payload }: RetailerSupplierItemsSetSelectedLocationId,
  ) {
    ctx.patchState({ selectedLocationId: payload });

    ctx.dispatch(new RetailerSupplierItemsGetMany());
  }

  @Action(RetailerSupplierItemsResetSelectedLocationId)
  resetSelectedLocationId(ctx: StateContext<RetailerSupplierItemsStateModel>) {
    ctx.patchState({ selectedLocationId: ALL_LOCATIONS_OPTION_ID });

    ctx.dispatch(new RetailerSupplierItemsGetMany());
  }

  getQuery(state: RetailerSupplierItemsStateModel) {
    const { categories, codeName } = state.filters;
    const { selectedLocationId } = state;
    const qb = new QueryBuilder<RetailerSupplierItem & RetailerSupplierItemsRequestProps>({
      filtering: [],
      paging: { offset: state.requestMetadata.page * state.requestMetadata.limit, limit: state.requestMetadata.limit },
      ordering: [
        {
          by: 'name.en',
          dir: 'asc',
        },
        { by: 'id', dir: 'desc' },
      ],
    });

    if (categories?.length) {
      qb.filtering.setFilter({
        by: 'category.id',
        match: categories,
        op: 'in',
      });
    }

    if (codeName?.length) {
      qb.filtering.withGroup('or', [
        {
          by: 'itemCode',
          match: codeName,
          op: 'like',
        },
        {
          by: 'name.en',
          match: codeName,
          op: 'like',
        },
        {
          by: 'retailerItem.code',
          match: codeName,
          op: 'like',
        },
        {
          by: 'supplierItem.name.en',
          match: codeName,
          op: 'like',
        },
      ]);
    }

    if (selectedLocationId !== ALL_LOCATIONS_OPTION_ID) {
      qb.filtering.setFilter({
        by: 'branch.id',
        match: selectedLocationId as string,
        op: 'eq',
      });
    }

    return qb.build();
  }
}
