import { AccountingEventType, Branch, IdType, IntegrationEventType, InventoryEventType, Supplier } from '@supy/common';

import { CreateBranchRequest } from './branch.model';
import { CreateOutletRequest } from './outlet.model';

export interface CreateRetailerMetadataRequest {
  readonly color?: string;
}

export interface CreateRetailerRequest {
  readonly name: string;
  readonly country?: IdType;
  readonly logoUrl?: string;

  readonly metadata?: CreateRetailerMetadataRequest;
}

export interface UpdateRetailerMetadataRequest {
  readonly color?: string;
}

export interface UpdateRetailerRequest {
  readonly name?: string;

  readonly logoUrl?: string;

  readonly metadata?: UpdateRetailerMetadataRequest;
}

export interface UploadImageRequest {
  readonly file: Blob;
}

export interface UploadRetailerImageResponse {
  readonly logoUrl: string;
}

export interface GetRetailerSupplierResponse extends Supplier {
  readonly branches?: Partial<Branch>[];
  readonly defaultTaxId?: string;
}

export interface CreateRetailerWithChildrenRequest {
  readonly retailer: CreateRetailerRequest;
  readonly outlet: CreateOutletRequest;
  readonly branch: CreateBranchRequest;
}

export interface GetAllowedEventDateLimitsRequest {
  readonly locationIds?: string[];
  readonly branchIds?: string[];
  readonly retailerId: string;
  readonly eventType: InventoryEventType | AccountingEventType | IntegrationEventType;
}

export interface GetAllowedEventDateLimitsResponse {
  readonly minDate: string;
  readonly maxDate?: string;
}

export interface AllowedEventDateLimits {
  readonly minimumDate: Date;
  readonly maximumDate: Date | null;
}

export function deserializeAllowedEventDateLimits(data: GetAllowedEventDateLimitsResponse): AllowedEventDateLimits {
  return {
    minimumDate: new Date(data.minDate),
    maximumDate: data.maxDate ? new Date(data.maxDate) : null,
  };
}
