import {
  AccountingIntegrationActionEnum,
  BranchActionEnum,
  CategoryOrderActionEnum,
  CentralKitchenItemActionEnum,
  CentralKitchenOrderActionEnum,
  CentralKitchenPriceListActionEnum,
  ChannelActionEnum,
  CreditNoteActionEnum,
  CustomerActionEnum,
  CustomerGroupActionEnum,
  DashboardActionEnum,
  FinancialReportActionEnum,
  GrnActionEnum,
  InventoryItemActionEnum,
  InventoryReportActionEnum,
  OrderActionEnum,
  PosIntegrationActionEnum,
  ProcurementReportActionEnum,
  ProductionActionEnum,
  RecipeActionEnum,
  ResourceEnum,
  RetailerActionEnum,
  RetailerItemActionEnum,
  StockCountActionEnum,
  SupplierReturnActionEnum,
  TransferActionEnum,
  UserActionEnum,
  WastageActionEnum,
} from '@supy.api/permissions';

import { AUTHZ_DEFAULT_ID, ResourceCheck } from '../core';

export const commonResourcesWithActions: ResourceCheck[] = [
  {
    resource: {
      kind: ResourceEnum.Retailer,
      id: AUTHZ_DEFAULT_ID,
    },
    actions: [RetailerActionEnum.UpdateSettings],
  },
  {
    resource: {
      kind: ResourceEnum.Branch,
      id: AUTHZ_DEFAULT_ID,
    },
    actions: [BranchActionEnum.UpdateMetadata],
  },
  {
    resource: {
      kind: ResourceEnum.PosIntegration,
      id: AUTHZ_DEFAULT_ID,
    },
    actions: [
      PosIntegrationActionEnum.ManageTenants,
      PosIntegrationActionEnum.UpdateItemMapping,
      PosIntegrationActionEnum.ImportSales,
    ],
  },
  {
    resource: {
      kind: ResourceEnum.User,
      id: AUTHZ_DEFAULT_ID,
    },
    actions: [UserActionEnum.ViewList],
  },
  {
    resource: {
      kind: ResourceEnum.Dashboard,
      id: AUTHZ_DEFAULT_ID,
    },
    actions: [
      DashboardActionEnum.ViewProcurement,
      DashboardActionEnum.ViewTheoreticalCost,
      DashboardActionEnum.ViewActualCost,
      DashboardActionEnum.ViewVariance,
      DashboardActionEnum.ViewWastage,
      DashboardActionEnum.ViewSales,
      DashboardActionEnum.ViewTransfer,
    ],
  },
  {
    resource: {
      id: AUTHZ_DEFAULT_ID,
      kind: ResourceEnum.Order,
    },
    actions: [OrderActionEnum.Draft, OrderActionEnum.ViewList, OrderActionEnum.Submit],
  },
  {
    resource: {
      id: AUTHZ_DEFAULT_ID,
      kind: ResourceEnum.CategoryOrder,
    },
    actions: [CategoryOrderActionEnum.View, CategoryOrderActionEnum.Create, CategoryOrderActionEnum.UpdateSupplier],
  },
  {
    resource: {
      id: AUTHZ_DEFAULT_ID,
      kind: ResourceEnum.CentralKitchenOrder,
    },
    actions: [
      CentralKitchenOrderActionEnum.Create,
      CentralKitchenOrderActionEnum.Confirm,
      CentralKitchenOrderActionEnum.Ship,
      CentralKitchenOrderActionEnum.Reject,
    ],
  },
  {
    resource: {
      id: AUTHZ_DEFAULT_ID,
      kind: ResourceEnum.RetailerItem,
    },
    actions: [RetailerItemActionEnum.Create, RetailerItemActionEnum.ViewList],
  },
  {
    resource: {
      id: AUTHZ_DEFAULT_ID,
      kind: ResourceEnum.Recipe,
    },
    actions: [RecipeActionEnum.View, RecipeActionEnum.Create, RecipeActionEnum.UpdateCost],
  },
  {
    resource: {
      id: AUTHZ_DEFAULT_ID,
      kind: ResourceEnum.Channel,
    },
    actions: [ChannelActionEnum.View],
  },
  {
    resource: {
      id: AUTHZ_DEFAULT_ID,
      kind: ResourceEnum.Grn,
    },
    actions: [
      GrnActionEnum.Create,
      GrnActionEnum.Draft,
      GrnActionEnum.ViewList,
      GrnActionEnum.View,
      GrnActionEnum.UpdateInvoicePrices,
      GrnActionEnum.UpdateQuantities,
      GrnActionEnum.UpdateItemPrices,
      GrnActionEnum.UpdateLocked,
      GrnActionEnum.Post,
      GrnActionEnum.CreateWithoutOrder,
    ],
  },
  {
    resource: {
      id: AUTHZ_DEFAULT_ID,
      kind: ResourceEnum.CreditNote,
    },
    actions: [CreditNoteActionEnum.ViewList],
  },
  {
    resource: {
      id: AUTHZ_DEFAULT_ID,
      kind: ResourceEnum.SupplierReturn,
    },
    actions: [SupplierReturnActionEnum.ViewList],
  },
  {
    resource: {
      id: AUTHZ_DEFAULT_ID,
      kind: ResourceEnum.InventoryItem,
    },
    actions: [InventoryItemActionEnum.View],
  },
  {
    resource: {
      id: AUTHZ_DEFAULT_ID,
      kind: ResourceEnum.Wastage,
    },
    actions: [WastageActionEnum.View],
  },
  {
    resource: {
      id: AUTHZ_DEFAULT_ID,
      kind: ResourceEnum.StockCount,
    },
    actions: [StockCountActionEnum.ViewList],
  },
  {
    resource: {
      id: AUTHZ_DEFAULT_ID,
      kind: ResourceEnum.Production,
    },
    actions: [ProductionActionEnum.View],
  },
  {
    resource: {
      id: AUTHZ_DEFAULT_ID,
      kind: ResourceEnum.Transfer,
    },
    actions: [TransferActionEnum.View],
  },
  {
    resource: {
      id: AUTHZ_DEFAULT_ID,
      kind: ResourceEnum.AccountingIntegration,
    },
    actions: [AccountingIntegrationActionEnum.UpdateMapping],
  },
  {
    resource: {
      id: AUTHZ_DEFAULT_ID,
      kind: ResourceEnum.ProcurementReport,
    },
    actions: [ProcurementReportActionEnum.Generate],
  },
  {
    resource: {
      id: AUTHZ_DEFAULT_ID,
      kind: ResourceEnum.FinancialReport,
    },
    actions: [FinancialReportActionEnum.Generate],
  },
  {
    resource: {
      id: AUTHZ_DEFAULT_ID,
      kind: ResourceEnum.InventoryReport,
    },
    actions: [InventoryReportActionEnum.Generate],
  },
  {
    resource: {
      id: AUTHZ_DEFAULT_ID,
      kind: ResourceEnum.Customer,
    },
    actions: [CustomerActionEnum.ViewList],
  },
  {
    resource: {
      id: AUTHZ_DEFAULT_ID,
      kind: ResourceEnum.CustomerGroup,
    },
    actions: [CustomerGroupActionEnum.ViewList],
  },
  {
    resource: {
      id: AUTHZ_DEFAULT_ID,
      kind: ResourceEnum.CentralKitchenPriceList,
    },
    actions: [CentralKitchenPriceListActionEnum.ViewList],
  },
  {
    resource: {
      id: AUTHZ_DEFAULT_ID,
      kind: ResourceEnum.CentralKitchenItem,
    },
    actions: [CentralKitchenItemActionEnum.ViewList],
  },
];
