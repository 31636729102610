import { Observable } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { ResourceEnum, UserActionEnum } from '@supy.api/permissions';

import { IsAllowedRequest, PermissionHelper, PermissionStrategy, Principal, Resource } from '@supy/authz';

@Injectable({ providedIn: 'root' })
class Create extends PermissionStrategy {
  private readonly permissionHelper = inject(PermissionHelper);

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.User,
      action: UserActionEnum.Create,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
class UpdateRole extends PermissionStrategy {
  private readonly permissionHelper = inject(PermissionHelper);

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.User,
      action: UserActionEnum.UpdateRole,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
class UpdateLocations extends PermissionStrategy {
  private readonly permissionHelper = inject(PermissionHelper);

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.User,
      action: UserActionEnum.UpdateLocations,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
class Delete extends PermissionStrategy {
  private readonly permissionHelper = inject(PermissionHelper);

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.User,
      action: UserActionEnum.Delete,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
class UpdateDetails extends PermissionStrategy {
  private readonly permissionHelper = inject(PermissionHelper);

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.User,
      action: UserActionEnum.UpdateDetails,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
class View extends PermissionStrategy {
  private readonly permissionHelper = inject(PermissionHelper);

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.User,
      action: UserActionEnum.View,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
class ViewList extends PermissionStrategy {
  private readonly permissionHelper = inject(PermissionHelper);

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.User,
      action: UserActionEnum.ViewList,
      resource,
      principal,
    });
  }
}

export class UsersPermissionStrategy {
  static Create = Create;
  static UpdateDetails = UpdateDetails;
  static UpdateRole = UpdateRole;
  static UpdateLocations = UpdateLocations;
  static Delete = Delete;
  static View = View;
  static ViewList = ViewList;
}
