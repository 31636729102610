import { firstValueFrom } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { Branch } from '@supy/common';

import { BranchesService } from '../../services';

@Injectable()
export class BranchResolver {
  private readonly branchesService = inject(BranchesService);

  resolve(route: ActivatedRouteSnapshot): Promise<Branch> {
    return firstValueFrom(this.branchesService.getBranch(route.params.branchId as string));
  }
}
