import { Observable } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { ResourceEnum, RetailerActionEnum } from '@supy.api/permissions';

import { IsAllowedRequest, PermissionHelper, PermissionStrategy, Principal, Resource } from '@supy/authz';

@Injectable({ providedIn: 'root' })
class UpdateSettings extends PermissionStrategy {
  private readonly permissionHelper = inject(PermissionHelper);

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.Retailer,
      action: RetailerActionEnum.UpdateSettings,
      resource,
      principal,
    });
  }
}

export class SettingsPermissionStrategy {
  static UpdateSettings = UpdateSettings;
}
