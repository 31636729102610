"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GrnActionEnum = void 0;
var GrnActionEnum;
(function (GrnActionEnum) {
  GrnActionEnum["Create"] = "create";
  GrnActionEnum["CreateWithoutOrder"] = "create:without-order";
  GrnActionEnum["UpdateQuantities"] = "update:quantities";
  GrnActionEnum["UpdateInvoicePrices"] = "update:invoice-prices";
  GrnActionEnum["UpdateItemPrices"] = "update:item-prices";
  GrnActionEnum["UpdateLocked"] = "update:locked";
  GrnActionEnum["Draft"] = "draft";
  GrnActionEnum["ResolveDispute"] = "dispute:resolve";
  GrnActionEnum["Lock"] = "lock";
  GrnActionEnum["View"] = "view";
  GrnActionEnum["Post"] = "post";
  GrnActionEnum["ViewList"] = "view:list";
  GrnActionEnum["ExportList"] = "export:list";
  GrnActionEnum["Export"] = "export";
  GrnActionEnum["Discard"] = "discard";
  /**
   * @deprecated
   */
  // Unlock = 'unlock',
  // UpdateStock = 'update:stock',
})(GrnActionEnum = exports.GrnActionEnum || (exports.GrnActionEnum = {}));
