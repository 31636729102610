import { Query, Supplier } from '@supy/common';

import { RetailerSuppliersFilters, RetailerSuppliersRequestMetadata } from '../state';

export class RetailerSuppliersGetMany {
  static readonly type = '[RetailerSuppliers] GetMany';
}

export class RetailerBranchesSuppliersGetMany {
  static readonly type = '[BranchesSuppliers] GetMany';
}

export class RetailerBranchesSuppliersGetAll {
  static readonly type = '[BranchesSuppliers] GetAll';

  constructor(readonly payload: { query: Query<Supplier> }) {}
}

export class RetailerSuppliersGetDetailed {
  static readonly type = '[RetailerSuppliers] GetDetailed';
  constructor(readonly payload: { retailerId: string; supplierId: string }) {}
}

export class RetailerSuppliersResetCurrent {
  static readonly type = '[RetailerSuppliers] ResetCurrent';
}

export class RetailerSuppliersUpdate {
  static readonly type = '[RetailerSuppliers] Update';
  constructor(readonly payload: { id: string; body: Supplier }) {}
}

export class RetailerSuppliersDelete {
  static readonly type = '[RetailerSuppliers] Delete';
  constructor(readonly payload: { retailerId: string; supplierId: string }) {}
}

export class RetailerSuppliersInitFilters {
  static readonly type = '[RetailerSuppliers] InitFilters';
}

export class RetailerSuppliersSetFilter {
  static readonly type = '[RetailerSuppliers] SetFilter';
  constructor(readonly payload: RetailerSuppliersFilters) {}
}

export class RetailerSuppliersPatchFilter {
  static readonly type = '[RetailerSuppliers] PatchFilter';
  constructor(readonly payload: Partial<RetailerSuppliersFilters>) {}
}

export class RetailerSuppliersResetFilter {
  static readonly type = '[RetailerSuppliers] ResetFilter';
}

export class RetailerSuppliersPatchRequestMeta {
  static readonly type = '[RetailerSuppliers] PatchRequestMeta';
  constructor(readonly payload: Partial<RetailerSuppliersRequestMetadata>) {}
}

export class RetailerSuppliersGetRetailerBranches {
  static readonly type = '[RetailerSuppliers] GetRetailerBranches';
  constructor(readonly payload: { retailerId: string }) {}
}
