import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { IQueryResponse, Query, Retailer, Supplier, User } from '@supy/common';

import { RETAILERS_BFF_URI, RETAILERS_URI } from '../../config';
import {
  CreateRetailerRequest,
  CreateRetailerWithChildrenRequest,
  DetailedRetailerSupplier,
  GetAllowedEventDateLimitsRequest,
  GetAllowedEventDateLimitsResponse,
  GetRetailerSupplierResponse,
  ImportSupplierItemsRequest,
  RetailerSupplierItem,
  RetailerSupplierItemsRequestProps,
  UpdateRetailerRequest,
  UpdateRetailerSupplierItemsRequest,
  UploadImageRequest,
  UploadRetailerImageResponse,
} from '../../core';

@Injectable({
  providedIn: 'root',
})
export class RetailersService {
  readonly #uriBff = inject(RETAILERS_BFF_URI);
  readonly #httpClient = inject(HttpClient);
  readonly #uri = inject(RETAILERS_URI);

  get(id: string): Observable<Retailer> {
    return this.#httpClient.get<Retailer>(`${this.#uriBff}/${id}`);
  }

  getMany(query: Query<Retailer>): Observable<IQueryResponse<Retailer>> {
    return this.#httpClient.get<IQueryResponse<Retailer>>(`${this.#uriBff}/all`, { params: query.toQueryParams() });
  }

  create(body: CreateRetailerRequest): Observable<Retailer> {
    return this.#httpClient.post<Retailer>(this.#uri, body);
  }

  createWithChildren(body: CreateRetailerWithChildrenRequest): Observable<Retailer> {
    return this.#httpClient.post<Retailer>(this.#uriBff, body);
  }

  generateApiKey(id: string): Observable<Retailer> {
    return this.#httpClient.post<Retailer>(`${this.#uriBff}/${id}/generate-api-key`, {});
  }

  update(id: string, retailer: UpdateRetailerRequest): Observable<void> {
    return this.#httpClient.patch<void>(`${this.#uri}/${id}`, retailer);
  }

  delete(id: string): Observable<void> {
    return this.#httpClient.delete<void>(`${this.#uri}/${id}`);
  }

  uploadImage(body: UploadImageRequest): Observable<UploadRetailerImageResponse> {
    const formData = new FormData();

    formData.append('file', body.file);

    return this.#httpClient.post<UploadRetailerImageResponse>(`${this.#uri}/image`, formData);
  }

  getUsers(id: string, query: Query<User>): Observable<IQueryResponse<User>> {
    return this.#httpClient.get<IQueryResponse<User>>(`${this.#uri}/${id}/users`, { params: query.toQueryParams() });
  }

  getUserSuppliers(id: string, query: Query<Supplier>): Observable<IQueryResponse<GetRetailerSupplierResponse>> {
    return this.#httpClient.get<IQueryResponse<GetRetailerSupplierResponse>>(`${this.#uriBff}/${id}/suppliers`, {
      params: query.toQueryParams(),
    });
  }

  getSupplier({
    retailerId,
    supplierId,
  }: {
    retailerId: string;
    supplierId: string;
  }): Observable<DetailedRetailerSupplier> {
    return this.#httpClient.get<DetailedRetailerSupplier>(
      `${this.#uriBff}/${retailerId}/suppliers/${supplierId}/detailed`,
    );
  }

  unlinkSupplier({ retailerId, supplierId }: { retailerId: string; supplierId: string }): Observable<void> {
    return this.#httpClient.delete<void>(`${this.#uriBff}/${retailerId}/suppliers/${supplierId}`);
  }

  getSuppliers(
    id: string,
    query: Query<GetRetailerSupplierResponse>,
  ): Observable<IQueryResponse<GetRetailerSupplierResponse>> {
    return this.#httpClient.get<IQueryResponse<GetRetailerSupplierResponse>>(
      `${this.#uriBff}/${id}/related-suppliers`,
      {
        params: query.toQueryParams(),
      },
    );
  }

  getSupplierItems(
    retailerId: string,
    supplierId: string,
    query: Query<RetailerSupplierItem & RetailerSupplierItemsRequestProps>,
  ): Observable<IQueryResponse<RetailerSupplierItem>> {
    return this.#httpClient.get<IQueryResponse<RetailerSupplierItem>>(
      `${this.#uriBff}/${retailerId}/suppliers/${supplierId}/items`,
      {
        params: query.toQueryParams(),
      },
    );
  }

  updateSupplierItems(
    retailerId: string,
    supplierId: string,
    body: UpdateRetailerSupplierItemsRequest,
  ): Observable<void> {
    return this.#httpClient.patch<void>(`${this.#uriBff}/${retailerId}/suppliers/${supplierId}/items`, body);
  }

  importSupplierItems(retailerId: string, supplierId: string, body: ImportSupplierItemsRequest): Observable<void> {
    return this.#httpClient.post<void>(`${this.#uriBff}/${retailerId}/suppliers/${supplierId}/items/import`, body);
  }

  getAllowedEventDateLimits(body: GetAllowedEventDateLimitsRequest): Observable<GetAllowedEventDateLimitsResponse> {
    return this.#httpClient.get<GetAllowedEventDateLimitsResponse>(
      `${this.#uriBff}/${body.retailerId}/event-date-limits`,
      {
        params: {
          eventType: body.eventType,
          ...(body.branchIds?.length ? { branchIds: JSON.stringify(body.branchIds) } : undefined),
          ...(body.locationIds?.length ? { locationIds: JSON.stringify(body.locationIds) } : undefined),
        },
      },
    );
  }
}
