import { Observable } from 'rxjs';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { EndpointConfig, VersionCheckConfig } from '@supy/common';
import { APP_CONFIG, APP_NAME, Environment, EnvironmentType } from '@supy/core';

interface HeadersConfig {
  readonly name: string;
  readonly version: string;
  readonly environment: EnvironmentType;
}

@Injectable()
export class ClientVersionInterceptor<T, R> implements HttpInterceptor {
  private readonly config = inject<EndpointConfig & VersionCheckConfig & Environment>(APP_CONFIG);
  readonly #appName = inject(APP_NAME);

  intercept(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<R>> {
    const { apiUrl, apiUrlBff, apiUrlPrefix, environment, versionCheck } = this.config;

    if (req.url.startsWith(`${apiUrl}${apiUrlPrefix}`) || req.url.startsWith(`${apiUrlBff}${apiUrlPrefix}`)) {
      return next.handle(this.addVersionHeader(req, { name: this.#appName, version: versionCheck.hash, environment }));
    }

    return next.handle(req);
  }

  private addVersionHeader<T>(request: HttpRequest<T>, headersConfig: HeadersConfig): HttpRequest<T> {
    const appHeaders: string = Object.entries(headersConfig).reduce((acc, [key, value]) => {
      return acc + `${key}=${value};`;
    }, '');

    return request.clone({
      headers: request.headers.set('X-Supy-App', appHeaders),
    });
  }
}
