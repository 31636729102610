import { map, Observable } from 'rxjs';
import { inject, Injectable } from '@angular/core';

import { UsersService } from '../../services';

@Injectable()
export class SyncUserResolver {
  private readonly usersService = inject(UsersService);

  resolve(): Observable<boolean> {
    return this.usersService.syncUser().pipe(map(() => true));
  }
}
