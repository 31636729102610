import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ClassProvider, Provider } from '@angular/core';

import { ClientVersionInterceptor } from './client-version';
import { RetailerIdInterceptor } from './retailer-id';

export const HTTP_RETAILERS_INTERCEPTOR_PROVIDERS: Provider[] = [
  { provide: HTTP_INTERCEPTORS, useClass: RetailerIdInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ClientVersionInterceptor, multi: true },
];

export const HTTP_CLIENT_VERSION_HEADER_INTERCEPTOR_PROVIDER: ClassProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: ClientVersionInterceptor,
  multi: true,
};
