import { firstValueFrom } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { IQueryResponse, Query, QueryPaging, Supplier, SupplierState } from '@supy/common';

import { RetailersService } from '../../services';

@Injectable()
export class BranchSuppliersResolver {
  private readonly retailersService = inject(RetailersService);

  resolve(route: ActivatedRouteSnapshot): Promise<IQueryResponse<Supplier>> {
    const query = new Query<Supplier>({
      paging: QueryPaging.NoLimit,
      filtering: [{ by: 'state', op: 'eq', match: SupplierState.Active }],
    });

    return firstValueFrom(this.retailersService.getUserSuppliers(route.params.id as string, query));
  }
}
