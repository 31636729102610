import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { IQueryResponse, OutletData, Query, Supplier, User } from '@supy/common';

import { OUTLETS_BFF_URI } from '../../config';
import {
  BatchUpdateOutletsRequest,
  CreateOutletRequest,
  CreateOutletWithChildrenRequest,
  OutletsProps,
  UpdateOutletRequest,
} from '../../core';

@Injectable({ providedIn: 'root' })
export class OutletsService {
  protected readonly httpClient = inject(HttpClient);
  private readonly uriBff = inject<string>(OUTLETS_BFF_URI);

  getOutletsBff(query: Query<OutletsProps>): Observable<IQueryResponse<OutletData>> {
    return this.httpClient.get<IQueryResponse<OutletData>>(this.uriBff, { params: query.toQueryParams() });
  }

  getOutletBff(id: string): Observable<OutletData> {
    return this.httpClient.get<OutletData>(`${this.uriBff}/${id}`);
  }

  getOutletUsersBff(id: string, query: Query<User>): Observable<IQueryResponse<User>> {
    return this.httpClient.get<IQueryResponse<User>>(`${this.uriBff}/${id}/users`, { params: query.toQueryParams() });
  }

  getOutletsSuppliersBff(ids: string[], query: Query<Supplier>): Observable<IQueryResponse<Supplier>> {
    query.filtering.setFilter({ by: 'branchIds', op: 'eq', match: ids });

    return this.httpClient.get<IQueryResponse<Supplier>>(`${this.uriBff}/suppliers`, { params: query.toQueryParams() });
  }

  createOutletBff(body: CreateOutletRequest): Observable<OutletData> {
    return this.httpClient.post<OutletData>(this.uriBff, body);
  }

  createOutletWithChildrenBff(body: CreateOutletWithChildrenRequest): Observable<OutletData> {
    return this.httpClient.post<OutletData>(this.uriBff, body);
  }

  editOutletBff(id: string, body: UpdateOutletRequest): Observable<void> {
    return this.httpClient.patch<void>(`${this.uriBff}/${id}`, body);
  }

  batchEditOutletsBff(body: BatchUpdateOutletsRequest): Observable<void> {
    return this.httpClient.patch<void>(`${this.uriBff}`, body);
  }

  deleteOutletBff(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.uriBff}/${id}`);
  }
}
