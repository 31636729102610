import { Observable, switchMap } from 'rxjs';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Store } from '@ngxs/store';

import { EndpointConfig } from '@supy/common';
import { APP_CONFIG } from '@supy/core';

import { CurrentRetailerState } from '../../store';

@Injectable()
export class RetailerIdInterceptor<T, R> implements HttpInterceptor {
  private readonly store = inject(Store);
  private readonly config = inject<EndpointConfig>(APP_CONFIG);

  intercept(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<R>> {
    const { apiUrl, apiUrlBff, apiUrlPrefix } = this.config;

    if (request.url.startsWith(`${apiUrl}${apiUrlPrefix}`) || request.url.startsWith(`${apiUrlBff}${apiUrlPrefix}`)) {
      return this.store.selectOnce(CurrentRetailerState.get).pipe(
        switchMap(retailerId => {
          let updatedRequest = request;

          if (retailerId) {
            updatedRequest = this.addTenantHeader(request, retailerId);
          }

          return next.handle(updatedRequest);
        }),
      );
    }

    return next.handle(request);
  }

  private addTenantHeader<T>(request: HttpRequest<T>, retailerId: string) {
    return request.clone({
      headers: request.headers.set('X-Supy-Tenant-Id', retailerId),
    });
  }
}
