import { Observable } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { AuthzService } from '../services';
import { PermissionStrategy } from '../strategies';

export interface AuthzGuardRouteData {
  readonly permissionStrategy: PermissionStrategy;
}

@Injectable()
export class AuthzGuard {
  private readonly authzService = inject(AuthzService);

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const permissionStrategy = this.#getPermissionStrategyFromRoute(route);

    return this.authzService.hasAccess(permissionStrategy);
  }

  #getPermissionStrategyFromRoute(route: ActivatedRouteSnapshot): PermissionStrategy {
    const { permissionStrategy } = route.data as AuthzGuardRouteData;

    if (!permissionStrategy) {
      throw new Error(`Can't use AuthzGuard without permission strategy in route data`);
    }

    return permissionStrategy;
  }
}
