import produce from 'immer';
import { distinctUntilChanged, filter, of, switchMap, tap } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Action, NgxsOnInit, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { getCurrentScope } from '@sentry/browser';

import { QueryBuilder, QueryPaging, Supplier, SupplierState, SupplierType, ViewBranch } from '@supy/common';
import { DropdownTreeNode } from '@supy/components';
import { Nullable } from '@supy/core';
import { getLocalizedName } from '@supy/settings';
import { CurrentUserState, CurrentUserStateModel, UsersService } from '@supy/users';

import { AllowedEventDateLimits, deserializeAllowedEventDateLimits } from '../../core';
import { RetailersService } from '../../services';
import {
  CurrentRetailerCreateUser,
  CurrentRetailerGetAllowedEventDateLimits,
  CurrentRetailerSet,
  CurrentRetailerSuppliersGet,
  CurrentRetailerSuppliersSet,
  CurrentRetailerUpdateUser,
} from '../actions';

export interface CurrentRetailerStateModel {
  readonly retailerId?: Nullable<string>;
  readonly suppliers: Supplier[];
  readonly allowedEventDateLimits: Nullable<AllowedEventDateLimits>;
}

export const CURRENT_RETAILER_STATE_TOKEN = new StateToken<CurrentRetailerStateModel>('currentRetailer');

@State<CurrentRetailerStateModel>({
  name: CURRENT_RETAILER_STATE_TOKEN,
})
@Injectable()
export class CurrentRetailerState implements NgxsOnInit {
  readonly #document = inject(DOCUMENT);
  readonly #retailersService = inject(RetailersService);
  readonly #usersService = inject(UsersService);
  readonly #router = inject(Router);
  readonly #store = inject(Store);

  @Selector()
  static get(state: CurrentRetailerStateModel) {
    return state?.retailerId;
  }

  @Selector()
  static suppliersWithDisplayNameAsName(state: CurrentRetailerStateModel) {
    return state.suppliers.map(supplier => ({
      ...supplier,
      name: supplier.displayName,
    }));
  }

  @Selector()
  static suppliers(state: CurrentRetailerStateModel) {
    return state.suppliers;
  }

  @Selector([CurrentRetailerState, CurrentUserState])
  static userCentralKitchens(state: CurrentRetailerStateModel, currentUserState: CurrentUserStateModel) {
    const allRelatedCentralKitchenSuppliersMap = this.relatedCentralKitchensMap(state);

    const userCentralKitchens = CurrentUserState.isSuperadmin(currentUserState)
      ? this.relatedCentralKitchens(state)
      : currentUserState.user?.centralKitchens;

    const retailerCentralKitchens =
      userCentralKitchens?.reduce((acc, supplier) => {
        const supplierRetailerId = supplier.retailerId ?? supplier.metadata?.retailerId;

        if (state.retailerId === supplierRetailerId) {
          acc.push({
            ...supplier,
            ...allRelatedCentralKitchenSuppliersMap.get(supplier.id),
          });
        }

        return acc;
      }, [] as Supplier[]) ?? [];

    return retailerCentralKitchens;
  }

  @Selector()
  static relatedCentralKitchens(state: CurrentRetailerStateModel) {
    return state.suppliers?.filter(supp => supp.type === SupplierType.centralKitchen);
  }

  @Selector()
  static relatedCentralKitchensMap(state: CurrentRetailerStateModel) {
    return new Map<string, Supplier>(this.relatedCentralKitchens(state).map(supplier => [supplier.id, supplier]));
  }

  @Selector()
  static allowedEventDateLimits(state: CurrentRetailerStateModel) {
    return state.allowedEventDateLimits
      ? {
          minimumDate: new Date(state.allowedEventDateLimits.minimumDate),
          maximumDate: state.allowedEventDateLimits.maximumDate
            ? new Date(state.allowedEventDateLimits.maximumDate)
            : null,
        }
      : null;
  }

  @Selector([CurrentRetailerState, CurrentUserState])
  static retailer(state: CurrentRetailerStateModel, currentUserState: CurrentUserStateModel) {
    return currentUserState.user?.retailers?.find(retailer => retailer.id === state.retailerId);
  }

  @Selector([CurrentRetailerState, CurrentUserState])
  /**
   * Branches grouped by region list for dropdowns (filters, selects, etc.).
   * @returns {DropdownTreeNode<string>[]} - list of branches grouped by region (if any)
   */
  static locationsWithRegionsBranches(state: CurrentRetailerStateModel, currentUserState: CurrentUserStateModel) {
    const selectedRetailer = currentUserState.user?.retailers?.find(retailer => retailer.id === state.retailerId);

    return [...(selectedRetailer?.outlets ?? [])]
      .sort(a => (a.region ? -1 : 1))
      .reduce((list, outlet) => {
        if (outlet.region) {
          const existingRegion = list.find(({ id }) => id === outlet.region?.id);

          if (existingRegion) {
            existingRegion.children?.push({
              id: outlet.id,
              name: getLocalizedName(outlet.name),
              children: outlet.branches?.map(branch => ({ id: branch.id, name: branch.name })),
              unselectable: true,
            });
          } else {
            list.push({
              id: outlet.region.id,
              name: outlet.region.name,
              unselectable: true,
              children: [
                {
                  id: outlet.id,
                  name: getLocalizedName(outlet.name),
                  children: outlet.branches?.map(branch => ({ id: branch.id, name: branch.name })),
                  unselectable: true,
                },
              ],
            });
          }
        } else {
          list.push({
            id: outlet.id,
            name: getLocalizedName(outlet.name),
            children: outlet.branches?.map(branch => ({ id: branch.id, name: branch.name })),
            unselectable: true,
          });
        }

        return list;
      }, [] as DropdownTreeNode<string>[]);
  }

  @Selector([CurrentRetailerState, CurrentUserState])
  /**
   * Simple branches list for displaying. Not grouped by regions.
   * @returns {DropdownTreeNode<string>[]} - list of branches without grouping by regions
   */
  static locationsWithBranches(state: CurrentRetailerStateModel, currentUserState: CurrentUserStateModel) {
    const selectedRetailerOutlets =
      currentUserState.user?.retailers?.find(retailer => retailer.id === state.retailerId)?.outlets ?? [];

    return selectedRetailerOutlets.map(outlet => ({
      id: outlet.id,
      name: getLocalizedName(outlet.name),
      children: outlet.branches?.map(branch => ({ id: branch.id, name: branch.name })),
      unselectable: true,
    }));
  }

  @Selector([CurrentRetailerState, CurrentUserState])
  static outlets(state: CurrentRetailerStateModel, currentUserState: CurrentUserStateModel) {
    const selectedRetailer = currentUserState.user?.retailers?.find(retailer => retailer.id === state.retailerId);

    return selectedRetailer?.outlets ?? [];
  }

  @Selector([CurrentRetailerState, CurrentUserState])
  static branches(state: CurrentRetailerStateModel, currentUserState: CurrentUserStateModel) {
    const selectedRetailer = currentUserState.user?.retailers?.find(retailer => retailer.id === state.retailerId);

    return (
      selectedRetailer?.outlets?.flatMap<ViewBranch>(outlet =>
        outlet.branches.map(branch => ({ ...branch, name: `${getLocalizedName(outlet.name)} - ${branch.name}` })),
      ) ?? []
    );
  }

  @Selector([CurrentRetailerState, CurrentUserState])
  static idToLocation(state: CurrentRetailerStateModel, currentUserState: CurrentUserStateModel) {
    const selectedRetailer = currentUserState.user?.retailers?.find(retailer => retailer.id === state.retailerId);

    return new Map<string, ViewBranch>(
      (
        selectedRetailer?.outlets?.flatMap<ViewBranch>(outlet =>
          outlet.branches.map(branch => ({ ...branch, name: `${getLocalizedName(outlet.name)} - ${branch.name}` })),
        ) ?? []
      ).map(location => [location.id, location]),
    );
  }

  ngxsOnInit(ctx: StateContext<CurrentRetailerStateModel>): void {
    this.#store
      .select(CurrentRetailerState.get)
      .pipe(filter(Boolean), distinctUntilChanged())
      .subscribe(() => ctx.dispatch(new CurrentRetailerSuppliersGet(true)));
  }

  @Action(CurrentRetailerSet, { cancelUncompleted: true })
  set(ctx: StateContext<CurrentRetailerStateModel>, action: CurrentRetailerSet) {
    const { retailerId } = ctx.getState();

    const scope = getCurrentScope();

    scope.setTag('retailerId', retailerId);

    if (action.payload.id !== retailerId) {
      ctx.patchState({ retailerId: action.payload.id, suppliers: [] });
      ctx.dispatch(new CurrentRetailerSuppliersGet(true));

      if (retailerId && action.payload.id && !action.payload.stopRedirect) {
        const routes = this.#router.url.split('/');

        this.#document.location.replace(`/${routes?.length ? routes[1] : ''}`);
      }
    }
  }

  @Action(CurrentRetailerSuppliersGet, { cancelUncompleted: true })
  getSuppliers(ctx: StateContext<CurrentRetailerStateModel>, { forceFetch }: CurrentRetailerSuppliersGet) {
    const { suppliers } = ctx.getState();

    const qb = new QueryBuilder<Supplier>({
      paging: QueryPaging.NoLimit,
      filtering: {
        filtering: [
          { by: 'state', op: 'eq', match: SupplierState.Active },
          { by: 'state', op: 'eq', match: SupplierState.Pending },
        ],
        groups: [],
        condition: 'or',
      },
    });

    return !forceFetch && suppliers?.length
      ? of(suppliers)
      : this.#retailersService
          .getSuppliers(ctx.getState().retailerId as string, qb.build())
          .pipe(switchMap(({ data }) => ctx.dispatch(new CurrentRetailerSuppliersSet({ suppliers: data }))));
  }

  @Action(CurrentRetailerSuppliersSet)
  setSuppliers(ctx: StateContext<CurrentRetailerStateModel>, { payload: { suppliers } }: CurrentRetailerSuppliersSet) {
    ctx.patchState({ suppliers });
  }

  @Action(CurrentRetailerCreateUser)
  createUser(_: StateContext<CurrentRetailerStateModel>, { body }: CurrentRetailerCreateUser) {
    return this.#usersService.createRetailerUserBFF(body);
  }

  @Action(CurrentRetailerUpdateUser)
  updateUser(_: StateContext<CurrentRetailerStateModel>, { id, body }: CurrentRetailerUpdateUser) {
    return this.#usersService.updateRetailerUserBFF(id, body);
  }

  @Action(CurrentRetailerGetAllowedEventDateLimits)
  getAllowedEventDateLimits(
    ctx: StateContext<CurrentRetailerStateModel>,
    { payload }: CurrentRetailerGetAllowedEventDateLimits,
  ) {
    return this.#retailersService.getAllowedEventDateLimits(payload).pipe(
      tap(response => {
        ctx.setState(
          produce(draft => {
            draft.allowedEventDateLimits = deserializeAllowedEventDateLimits(response);
          }),
        );
      }),
    );
  }
}
