"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CategoryOrderActionEnum = void 0;
var CategoryOrderActionEnum;
(function (CategoryOrderActionEnum) {
  CategoryOrderActionEnum["Create"] = "create";
  CategoryOrderActionEnum["UpdateSupplier"] = "update:supplier";
  CategoryOrderActionEnum["Process"] = "process";
  CategoryOrderActionEnum["ProcessDraft"] = "process:draft";
  CategoryOrderActionEnum["Discard"] = "discard";
  CategoryOrderActionEnum["View"] = "view";
  CategoryOrderActionEnum["Approve"] = "approve";
})(CategoryOrderActionEnum = exports.CategoryOrderActionEnum || (exports.CategoryOrderActionEnum = {}));
