import { ColDef, NewValueParams } from '@ag-grid-community/core';
import {
  ChangeDetectionStrategy,
  Component,
  effect,
  forwardRef,
  inject,
  Injector,
  input,
  output,
  signal,
  viewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { RelatedBranch } from '@supy/common';
import { GridPocComponent, SwitchCellEditorComponent, SwitchCellRendererComponent } from '@supy/components';

export interface ActiveLocationsForm {
  readonly locations: RelatedBranch[];
}

@Component({
  selector: 'supy-active-locations-grid',
  templateUrl: './active-locations-grid.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./active-locations-grid.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ActiveLocationsGridComponent),
      multi: true,
    },
  ],
})
export class ActiveLocationsGridComponent {
  readonly #injector = inject(Injector);
  readonly grid = viewChild(GridPocComponent);

  protected locations = input.required<RelatedBranch[]>();
  protected isReadonly = input(false);
  protected locationChange = output<RelatedBranch[]>();

  protected readonly columnDefs = signal<ColDef<RelatedBranch>[]>([]);
  protected readonly defaultColDefs: ColDef<RelatedBranch> = {
    cellStyle: { border: 'none' },
  };

  onValueChange(value: boolean, locationId: string): void {
    const updated = this.locations().map(location => ({
      ...location,
      active: location.id === locationId ? value : location.active,
    }));

    this.locationChange.emit(updated);
  }

  protected setColumnDefs(): void {
    effect(
      () => {
        this.columnDefs.set([
          {
            field: 'name',
            headerName: $localize`:@@common.locationName:Location Name`,
            flex: 3,
          },
          {
            field: 'active',
            headerName: $localize`:@@grid.headers.active.label:Active`,
            flex: 1,
            onCellValueChanged: (event: NewValueParams<RelatedBranch, boolean>) => {
              this.onValueChange(event.newValue, event.data.id);
            },
            cellRenderer: SwitchCellRendererComponent,
            cellEditor: SwitchCellEditorComponent,
            cellRendererParams: {
              context: {
                disabled: (params: RelatedBranch) => params?.disabled || this.isReadonly(),
                size: 'small',
              },
            },
            cellEditorParams: {
              context: {
                disabled: (params: RelatedBranch) => params?.disabled || this.isReadonly(),
                size: 'small',
              },
            },
          },
        ]);
      },
      {
        injector: this.#injector,
        allowSignalWrites: true,
      },
    );
  }
}
