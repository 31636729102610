import { firstValueFrom } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { OutletData } from '@supy/common';

import { OutletsService } from '../../services';

@Injectable()
export class OutletResolver {
  private readonly outletsService = inject(OutletsService);

  resolve(route: ActivatedRouteSnapshot): Promise<OutletData> {
    return firstValueFrom(this.outletsService.getOutletBff(route.params.outletId as string));
  }
}
