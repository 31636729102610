import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { USER_SETTINGS_BFF_URI } from '../config';
import { UpdateUserSettingsRequest, UserSettings } from '../core';

@Injectable({
  providedIn: 'root',
})
export class UserSettingsService {
  private readonly httpClient = inject(HttpClient);
  private readonly bffUri = inject<string>(USER_SETTINGS_BFF_URI);

  get(): Observable<UserSettings> {
    return this.httpClient.get<UserSettings>(`${this.bffUri}`);
  }

  update(body: Partial<UpdateUserSettingsRequest>): Observable<void> {
    return this.httpClient.patch<void>(`${this.bffUri}`, body);
  }
}
