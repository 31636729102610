import { Observable } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { BranchActionEnum, ResourceEnum } from '@supy.api/permissions';

import { IsAllowedRequest, PermissionHelper, PermissionStrategy, Principal, Resource } from '@supy/authz';

@Injectable({ providedIn: 'root' })
class Create extends PermissionStrategy {
  private readonly permissionHelper = inject(PermissionHelper);

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.Branch,
      action: BranchActionEnum.Create,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
class Update extends PermissionStrategy {
  private readonly permissionHelper = inject(PermissionHelper);

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.Branch,
      action: BranchActionEnum.Update,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
class UpdateMetadata extends PermissionStrategy {
  private readonly permissionHelper = inject(PermissionHelper);

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.Branch,
      action: BranchActionEnum.UpdateMetadata,
      resource,
      principal,
    });
  }
}

export class BranchesPermissionStrategy {
  static Create = Create;
  static Update = Update;
  static UpdateMetadata = UpdateMetadata;
}
