import { Observable } from 'rxjs';
import { inject, Injectable } from '@angular/core';

import { AuthService } from '@supy/authentication';
import { Branch, IQueryResponse, Query, QueryPaging, SimpleUser } from '@supy/common';

import { BranchesService } from '../../services';

@Injectable()
export class UserBranchesResolver {
  private readonly authService = inject(AuthService);
  private readonly branchesService = inject(BranchesService);

  resolve(): Observable<IQueryResponse<Branch>> {
    const user = this.authService.user;
    const query = new Query<Branch>({
      paging: QueryPaging.NoLimit,
      filtering: [{ by: 'users', op: 'in', match: [user?.id as unknown as SimpleUser] }],
      ordering: [
        { by: 'name', dir: 'asc' },
        { by: 'id', dir: 'desc' },
      ],
    });

    return this.branchesService.getBranches(query);
  }
}
