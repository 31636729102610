import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { SETTINGS_BFF_URI } from '../config';
import {
  AccountingCategoryApi,
  CreateAccountingCategoryRequest,
  CreateRecipeCategoryRequest,
  CreateWastageTypeRequest,
  RecipeCategoryApi,
  SalesType,
  SalesTypeBase,
  SettingsApi,
  UpdateAccountingCategoryRequest,
  UpdateRecipeCategoryRequest,
  UpdateSettingsRequest,
  UpdateWastageTypeRequest,
  WastageTypeApi,
} from '../core';

@Injectable({ providedIn: 'root' })
export class SettingsService {
  private readonly httpClient = inject(HttpClient);
  private readonly bffUri = inject<string>(SETTINGS_BFF_URI);
  // FIXME: Rename this service to InventorySettingsService, all the related models and interfaces

  get(retailerId: string): Observable<SettingsApi> {
    return this.httpClient.get<SettingsApi>(`${this.bffUri}/${retailerId}`);
  }

  update({ id, ...body }: UpdateSettingsRequest): Observable<void> {
    return this.httpClient.patch<void>(`${this.bffUri}/${id}`, body);
  }

  createRecipeCategory(body: CreateRecipeCategoryRequest): Observable<RecipeCategoryApi> {
    return this.httpClient.post<RecipeCategoryApi>(`${this.bffUri}/recipe-categories`, body);
  }

  updateRecipeCategory(body: UpdateRecipeCategoryRequest): Observable<void> {
    return this.httpClient.patch<void>(`${this.bffUri}/recipe-categories/${body.id}`, body);
  }

  deleteRecipeCategory(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.bffUri}/recipe-categories/${id}`);
  }

  createWastageType(body: CreateWastageTypeRequest): Observable<WastageTypeApi> {
    return this.httpClient.post<WastageTypeApi>(`${this.bffUri}/wastage-types`, body);
  }

  updateWastageType(body: UpdateWastageTypeRequest): Observable<void> {
    return this.httpClient.patch<void>(`${this.bffUri}/wastage-types/${body.id}`, body);
  }

  deleteWastageType(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.bffUri}/wastage-types/${id}`);
  }

  createAccountingCategory(body: CreateAccountingCategoryRequest): Observable<AccountingCategoryApi> {
    return this.httpClient.post<AccountingCategoryApi>(`${this.bffUri}/accounting-categories`, body);
  }

  updateAccountingCategory(body: UpdateAccountingCategoryRequest): Observable<void> {
    return this.httpClient.patch<void>(`${this.bffUri}/accounting-categories/${body.id}`, body);
  }

  deleteAccountingCategory(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.bffUri}/accounting-categories/${id}`);
  }

  createSalesType(retailerId: string, salesType: SalesTypeBase): Observable<SalesType> {
    return this.httpClient.post<SalesType>(`${this.bffUri}/${retailerId}/sales-types`, salesType);
  }

  updateSalesType(retailerId: string, salesType: SalesTypeBase, salesTypeId: string): Observable<void> {
    return this.httpClient.patch<void>(`${this.bffUri}/${retailerId}/sales-types/${salesTypeId}`, salesType);
  }

  deleteSalesType(retailerId: string, salesTypeId: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.bffUri}/${retailerId}/sales-types/${salesTypeId}`);
  }

  makeDefaultSalesType(retailerId: string, salesTypeId: string): Observable<void> {
    return this.httpClient.post<void>(`${this.bffUri}/${retailerId}/sales-types/${salesTypeId}/make-default`, {});
  }
}
