import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { BranchRegion, CreateBranchRegionRequest } from '@supy/common';

import { RETAILER_SETTINGS_BFF_URI } from '../config';
import { RetailerSettings, UpdateRetailerSettingsRequest } from '../core';

@Injectable({
  providedIn: 'root',
})
export class RetailerSettingsService {
  private readonly httpClient = inject(HttpClient);
  private readonly bffUri = inject<string>(RETAILER_SETTINGS_BFF_URI);

  get(retailerId: string): Observable<RetailerSettings> {
    return this.httpClient.get<RetailerSettings>(`${this.bffUri}/${retailerId}`);
  }

  update(retailerId: string, body: UpdateRetailerSettingsRequest): Observable<void> {
    return this.httpClient.patch<void>(`${this.bffUri}/${retailerId}`, body);
  }

  createBranchRegion(body: CreateBranchRegionRequest): Observable<BranchRegion> {
    return this.httpClient.post<BranchRegion>(`${this.bffUri}/branch-regions`, body);
  }

  deleteBranchRegion(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.bffUri}/branch-regions/${id}`);
  }
}
