import { firstValueFrom } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { Retailer } from '@supy/common';

import { RetailersService } from '../../services';

@Injectable()
export class RetailerResolver {
  private readonly retailersService = inject(RetailersService);

  resolve(route: ActivatedRouteSnapshot): Promise<Retailer> {
    return firstValueFrom(this.retailersService.get(route.params.retailerId as string));
  }
}
