import { first, map, Observable, switchMap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { Destroyable } from '@supy/common';

import { AUTHZ_URI } from '../config';
import {
  CheckResourceRequest,
  CheckResourcesRequest,
  CheckResourcesResponse,
  CheckResourcesResult,
  IsAllowedRequest,
} from '../core';
import { PermissionStrategy } from '../strategies';

@Injectable({ providedIn: 'root' })
export class AuthzService extends Destroyable {
  private readonly uri = inject<string>(AUTHZ_URI);
  private readonly httpClient = inject(HttpClient);

  isAllowed(request: IsAllowedRequest): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this.uri}/permissions/is-authorized`, request);
  }

  checkResource(request: CheckResourceRequest): Observable<CheckResourcesResult> {
    return this.httpClient
      .post<CheckResourcesResult>(`${this.uri}/permissions/check-access`, request)
      .pipe(map(response => new CheckResourcesResult(response)));
  }

  checkResources(request: CheckResourcesRequest): Observable<CheckResourcesResponse> {
    return this.httpClient
      .post<CheckResourcesResponse>(`${this.uri}/permissions/check-access-many`, request)
      .pipe(map(response => new CheckResourcesResponse(response)));
  }

  hasAccess(permissionStrategy: PermissionStrategy): Observable<boolean> {
    return permissionStrategy.request().pipe(
      first(),
      switchMap(isAllowedRequest => this.isAllowed(isAllowedRequest)),
    );
  }
}
