import { delay, first, forkJoin, from, map, Observable, of, race, switchMap } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';

import { BaseUnleashGuard, UnleashService } from '@supy/common';
import { CurrentUserState } from '@supy/users';

import { CurrentRetailerState } from '../store';

/**
 * @description
 * Used as the guard that validates the provided `toggles` for the retailer based on the current retailer id
 *
 * @usage
 * ```Typescript
 * const routes: UnleashRoute[] = [
 *  {
 *    path: '',
 *    canActivate: [CurrentRetailerUnleashGuard],
 *    data: {
 *      unleashContext: {toggles: [ToggleFeature.SomeFeature]}
 *    }
 *  }
 * ];
 * ```
 */
@Injectable({ providedIn: 'root' })
export class CurrentRetailerUnleashGuard extends BaseUnleashGuard {
  protected readonly unleashService = inject(UnleashService);
  protected readonly store = inject(Store);

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const unleashContext = this.getContext(route);

    return forkJoin([
      this.getRetailerId().pipe(first(Boolean)),
      this.getRoleIds().pipe(first(Boolean)),
      this.getCurrentRetailerLocationIds().pipe(first()),
    ]).pipe(
      switchMap(([retailerId, roleIds, locationIds]) =>
        from(this.updateContext({ properties: { retailerId, roleIds, locationIds } })),
      ),
      switchMap(() => race(of('delay').pipe(delay(100)), this.unleashService.enabledTogglesChanges$.pipe(first()))),
      switchMap(() => from(this.activate(unleashContext.toggles))),
    );
  }

  protected getRetailerId(): Observable<string> {
    return this.store.select(CurrentRetailerState.get) as Observable<string>;
  }

  protected getRoleIds(): Observable<string> {
    return this.store.select(CurrentUserState.roleIdsCSV);
  }

  protected getCurrentRetailerLocationIds(): Observable<string> {
    return this.store
      .select(CurrentRetailerState.branches)
      .pipe(map(locations => locations.map(({ id }) => id).join(',')));
  }

  override async activate(toggles: string[]): Promise<boolean> {
    const enabledToggles = this.unleashService.enabledToggles;

    return Promise.resolve(toggles.some(toggle => enabledToggles.includes(toggle)));
  }
}
